import React, { useEffect, useState } from "react"
import { LinearProgress, Box, Typography } from "@mui/material"
import { format, addSeconds } from "date-fns"
import { useTranslation } from "react-i18next"

const CODE_EXPIRATION_TIME = 120

export interface CountDownProps {
  duration: number
  onFinish: () => void
}

export const CountDown: React.FC<CountDownProps> = ({ duration, onFinish }) => {
  const [timeLeft, setTimeLeft] = useState(duration)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(prevTimeLeft => {
        if (prevTimeLeft <= 0) {
          clearInterval(interval)
          return 0
        }
        return prevTimeLeft - 1
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (timeLeft === 0) {
      onFinish()
    }
  }, [timeLeft, onFinish])
  const formatOpts = i18n.language === "pl" ? "m 'min.' s 'sek.'" : "mm':'ss"
  const formattedTimeLeft = format(addSeconds(new Date(0), timeLeft), formatOpts)

  return (
    <Box sx={{ width: "80%" }}>
      <LinearProgress variant="determinate" value={(timeLeft / CODE_EXPIRATION_TIME) * 100} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 1,
        }}
      >
        <Typography>
          {t("login.form.countDown")}: {formattedTimeLeft}
        </Typography>
      </Box>
    </Box>
  )
}
