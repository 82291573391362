import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import React from "react"
import { useRouteError } from "react-router"
import { useTranslation } from "react-i18next"

export interface ApiErrorProps {}

export const ApiError: React.FC<ApiErrorProps> = () => {
  const { t } = useTranslation()
  const error = useRouteError()
  console.log(error)
  return (
    <Box mt={4}>
      <Typography>
        <FontAwesomeIcon icon={faCircleExclamation} color="#FF3A3A" /> {t("apiError.text")}
      </Typography>
    </Box>
  )
}
