import { FileWithPath } from "react-dropzone"
import ResultsForm from "../components/results/ResultsForm"
import { useCallback, useState } from "react"
import { DocumentDropZone } from "../components/results/DocumentDropZone"
import { Card, Container } from "@mui/material"

export const DocumentCreate = () => {
  const [acceptedFiles, setAcceptedFiles] = useState<FileWithPath[]>([])

  const handleFilesAccepted = useCallback((files: FileWithPath[]) => {
    setAcceptedFiles(files)
  }, [])

  return (
    <Container>
      {acceptedFiles.length === 0 && (
        <DocumentDropZone onFilesAccepted={handleFilesAccepted}></DocumentDropZone>
      )}
      {acceptedFiles.length > 0 && (
        <Card sx={{ padding: "20px" }}>
          {" "}
          <ResultsForm fileZone={[...acceptedFiles]}></ResultsForm>
        </Card>
      )}
    </Container>
  )
}
