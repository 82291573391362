import { getApps, initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"

const getConfig = () => {
  return {
    apiKey: process.env["REACT_APP_FIREBASE_API_KEY"],
    authDomain: process.env["REACT_APP_FIREBASE_AUTH_DOMAIN"],
    projectId: process.env["REACT_APP_FIREBASE_PROJECT_ID"],
    storageBucket: process.env["REACT_APP_FIREBASE_STORAGE_BUCKET"],
    messagingSenderId: process.env["REACT_APP_FIREBASE_MESSAGING_SENDER_ID"],
    appId: process.env["REACT_APP_FIREBASE_APP_ID"],
    measurementId: process.env["REACT_APP_FIREBASE_MEASUREMENT_ID"],
  }
}

export const getFirebaseClient = () => {
  const isFirebaseInitialized = getApps().length > 0
  const firebaseApp = isFirebaseInitialized ? getApps()[0] : initializeApp(getConfig())

  const firebaseAuth = getAuth(firebaseApp)
  return { firebaseAuth }
}

export const { firebaseAuth } = getFirebaseClient()
