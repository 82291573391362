import { createContext, useContext, useState, useEffect, PropsWithChildren } from "react"
import { Loader } from "../components/Loader"
import { ParsedTokenData, parseJwtToken } from "../utils/api"
import { getMe, Unauthorized } from "../api/user"
import { useFirebaseAuth } from "../hooks/useFirebaseAuth"

interface AuthContextType {
  isLoading: boolean
  isUnauthorizedUserInDBError?: boolean
  signedInEntity?: SignedInEntity
  signInDoctor: (token: string) => void
  signOut: () => Promise<void>
}

const AuthContext = createContext<AuthContextType>({
  isUnauthorizedUserInDBError: false,
  isLoading: false,
  signInDoctor: () => {},
  signOut: async () => {},
})

export const useAuthContext = () => useContext(AuthContext)

export type SignedInEntity = {
  firstName: string
  lastName: string
  token: string
  type: "doctor" | "patient"
}

export const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const { user, isUserLoading: isPatientLoading, signOutUser: signOutPatient } = useFirebaseAuth()
  const [signedInEntity, setSignedInEntity] = useState<SignedInEntity>()
  const [isLoading, setIsLoading] = useState(true)
  const [isUnauthorizedUserInDBError, setIsUnauthorizedUserInDBError] = useState(false)

  useEffect(() => {
    if (isPatientLoading) {
      return
    }

    const updateSignedInUser = async () => {
      if (user) {
        try {
          const token = await user.getIdToken()
          const meResult = await getMe({ token })
          setSignedInEntity({
            firstName: meResult.firstName,
            lastName: meResult.lastName,
            token: await user.getIdToken(),
            type: "patient",
          })
        } catch (error) {
          if (error instanceof Unauthorized) {
            setIsUnauthorizedUserInDBError(true)
          }
        }
      }
      setIsLoading(false)
    }
    updateSignedInUser()
  }, [user, isPatientLoading])

  useEffect(() => {
    const doctorToken = sessionStorage.getItem("token")
    if (doctorToken) {
      setSignedInEntity({
        firstName: sessionStorage.getItem("firstName") ?? "",
        lastName: sessionStorage.getItem("lastName") ?? "",
        token: doctorToken,
        type: "doctor",
      })
    }
  }, [])

  const signInDoctor = async (token: string) => {
    if (user) {
      await signOutPatient()
    }

    const doctorData: ParsedTokenData["access"] = parseJwtToken(token).access
    sessionStorage.setItem("token", token)
    sessionStorage.setItem("firstName", doctorData.firstName)
    sessionStorage.setItem("lastName", doctorData.lastName)

    setSignedInEntity({
      firstName: doctorData.firstName,
      lastName: doctorData.lastName,
      token: token,
      type: "doctor",
    })
  }

  const signOut = async () => {
    sessionStorage.removeItem("token")
    sessionStorage.removeItem("firstName")
    sessionStorage.removeItem("lastName")

    await signOutPatient()
    setSignedInEntity(undefined)
  }
  return (
    <AuthContext.Provider
      value={{
        isLoading: isPatientLoading,
        isUnauthorizedUserInDBError,
        signedInEntity,
        signOut,
        signInDoctor,
      }}
    >
      {isPatientLoading || isLoading ? <Loader /> : children}
    </AuthContext.Provider>
  )
}
