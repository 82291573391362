import * as React from "react"
import MenuIcon from "@mui/icons-material/Menu"
import { Tabs, Tab, Box, IconButton, Menu, MenuItem } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { SignedInEntity } from "../contexts"
import { useEffect, useState } from "react"

export const MainNavigation: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const [value, setValue] = useState(getLastPathPart(location.pathname))
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)

  useEffect(() => {
    setValue(getLastPathPart(location.pathname))
  }, [location.pathname])

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleChange = (_event: React.SyntheticEvent, newValue: SignedInEntity["type"]) => {
    setValue(newValue)
  }

  return (
    <>
      <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-Mui"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          <MenuItem key="patient" onClick={handleCloseNavMenu}>
            <LinkTab href="/login/patient" value="patient" label={t("nav.tab.patient")} />
          </MenuItem>
          <MenuItem key="doctor" onClick={handleCloseNavMenu}>
            <LinkTab href="/login/doctor" value="doctor" label={t("nav.tab.doctor")} />
          </MenuItem>
        </Menu>
      </Box>

      <Box sx={{ width: "100%", display: { xs: "none", md: "flex" } }}>
        <Tabs value={value} onChange={handleChange}>
          <LinkTab href="/login/patient" value="patient" label={t("nav.tab.patient")} />
          <LinkTab href="/login/doctor" value="doctor" label={t("nav.tab.doctor")} />
        </Tabs>
      </Box>
    </>
  )
}

interface LinkTabProps {
  label: string
  href: string
  value: string
}

function LinkTab(props: LinkTabProps) {
  const navigate = useNavigate()

  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault()
        navigate(props.href)
      }}
      {...props}
    />
  )
}

const getLastPathPart = (path: string) => {
  const pathSegments = path.split("/")
  return pathSegments[pathSegments.length - 1]
}
