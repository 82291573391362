import React, { useMemo, useState } from "react"
import { Box, Modal, Fade } from "@mui/material"

type ImagePreviewProps = {
  file: File
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ file }) => {
  const fileUrl = useMemo(() => URL.createObjectURL(file), [])
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const isBlob = ["application/pdf"].includes(file.type)
  return (
    <>
      <Box mt={2} textAlign="center">
        {isBlob ? (
          <object
            data={fileUrl}
            type={file.type}
            width="400"
            height="200"
            aria-label="PDF preview"
          ></object>
        ) : (
          <img
            onClick={handleOpen}
            src={fileUrl}
            alt="Document preview"
            style={{ maxHeight: "300px", borderRadius: "8px" }}
          />
        )}
      </Box>

      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open} timeout={500}>
          <img src={fileUrl} alt="Preview" style={{ maxHeight: "90%", maxWidth: "90%" }} />
        </Fade>
      </Modal>
    </>
  )
}

export default ImagePreview
