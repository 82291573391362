import { Box, Typography } from "@mui/material"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

export interface MeasurementListProps {
  measurements: string[]
}

export const MeasurementList: React.FC<MeasurementListProps> = ({ measurements }) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const initialDisplayCount = 8
  const displayCount = expanded ? measurements.length : initialDisplayCount

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <div>
      <Box
        component="ul"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          padding: { xs: 2, sm: 0 },
          margin: 0,
        }}
      >
        {measurements.slice(0, displayCount).map((measurement, index) => (
          <Box component="li" key={`${measurement}-${index}`} mr={3}>
            <Typography variant="body2" sx={{ marginLeft: "5px" }}>
              {measurement}
            </Typography>
          </Box>
        ))}
        {measurements.length > initialDisplayCount && !expanded && (
          <Box component="li" key="expand" mr={3}>
            <Typography
              variant="body2"
              sx={{
                marginLeft: "5px",
                textDecoration: "underline",
                color: "#597FFF",
                cursor: "pointer",
              }}
              onClick={handleExpandClick}
            >
              {t("documentList.showMore")}
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  )
}
