import { Box, Link, Typography } from "@mui/material"
import React from "react"
import logo from "../assets/images/teczka-pacjenta-logo.svg"
import fbIcon from "../assets/images/facebook-icon.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"

export const RegisterConfirmation: React.FC = () => {
  return (
    <Box px={2} pb={5} width="100%">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        sx={{ height: "100%" }}
      >
        <Box sx={{ marginBottom: 8.5 }}>
          <img src={logo} alt="Logo Teczka Pacjenta" width="138" />
        </Box>
        <Typography variant="h2" align="center">
          Twoje konto zostało aktywowane.
        </Typography>
        <Typography align="center">Przejdź do aplikacji Teczka Pacjenta</Typography>
        <Typography align="center">i zaloguj się.</Typography>
      </Box>
      <Box display="flex" alignSelf="flex-end" justifyContent="space-between">
        <Box display="flex" justifyContent="center" alignItems="center">
          <img src={fbIcon} alt="Teczka Pacjenta Facebook page" />
          <Link
            sx={{
              fontSize: 14,
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "150%",
              textDecorationLine: "underline",
              color: "#0B2A32",
              textDecorationColor: "#0B2A32",
              marginLeft: 1,
            }}
            href="https://www.facebook.com/people/Health-Folder/100090744653691/"
          >
            Teczka Pacjenta
          </Link>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <FontAwesomeIcon icon={faEnvelope} color="#A0AAB1" />
          <Link
            sx={{
              fontSize: 14,
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "150%",
              textDecorationLine: "underline",
              color: "#0B2A32",
              textDecorationColor: "#0B2A32",
              marginLeft: 1,
            }}
            href="mailto:kontakt@healthfolder.pl"
          >
            kontakt@healthfolder.pl
          </Link>
        </Box>
      </Box>
    </Box>
  )
}
