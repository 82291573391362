import { faFileImage } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, ButtonProps } from "@mui/material"
import React from "react"
import { getDoctorDocumentPreviewUrl, getPatientDocumentPreviewUrl } from "../api/document"
import { useAuthContext } from "../contexts"

export interface DocumentPreviewButtonProps {
  documentId: string
  buttonText?: string
}

export const DocumentPreviewButton: React.FC<DocumentPreviewButtonProps & ButtonProps> = ({
  documentId,
  buttonText,
  ...props
}) => {
  const { signedInEntity } = useAuthContext()

  const previewFile = async () => {
    if (!signedInEntity) {
      return
    }

    const handler =
      signedInEntity.type === "doctor" ? getDoctorDocumentPreviewUrl : getPatientDocumentPreviewUrl
    const url = await handler({ documentId, token: signedInEntity.token || "" })
    window.open(url, "_blank")
  }
  return (
    <Button
      onClick={previewFile}
      color="secondary"
      variant="contained"
      sx={{
        borderRadius: 25,
        paddingX: 1.5,
      }}
      {...props}
    >
      {buttonText || "Podgląd"}{" "}
      <FontAwesomeIcon icon={faFileImage} style={{ width: 16, height: 16, marginLeft: 8 }} />
    </Button>
  )
}
