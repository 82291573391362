import { useTranslation } from "react-i18next"
import logo from "../assets/images/teczka-pacjenta-logo.svg"
import logoEn from "../assets/images/logo-en.svg"
import { Outlet } from "react-router-dom"
import { Box, Card, CardContent, Container, Divider, Grid, Typography } from "@mui/material"

export const LoginLayout = () => {
  const { t, i18n } = useTranslation()
  const logoImg = i18n.language === "pl" ? logo : logoEn

  return (
    <Container
      component="main"
      sx={{
        height: "100%",
        display: "flex",
        flex: 1,
        justifyContent: "center",
        marginBottom: 12,
      }}
    >
      <Grid
        container
        columnSpacing={{ lg: 2, sm: 1 }}
        rowSpacing={{ xs: 4 }}
        justifyContent="center"
        alignItems="center"
        alignSelf="center"
        sx={{ height: { sm: "100%" } }}
      >
        <Grid item lg={4} md={5} sm={6} xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems={{ sm: "flex-start", xs: "center" }}
          >
            <img src={logoImg} alt="Logo" width={244} />
            <Divider sx={{ width: 244, borderWidth: 2, borderColor: "#A0AAB1", my: 6 }} />
            <Typography variant="h2" fontSize={24} fontFamily="Poppins">
              {t("login.title1")}
            </Typography>
            <Typography variant="h2" fontSize={24} fontFamily="Poppins">
              {t("login.title2")}
            </Typography>
            <Typography variant="h2" fontSize={24} fontFamily="Poppins">
              {t("login.title3")}
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={5} md={6} sm={6} xs={12}>
          <Card>
            <CardContent sx={{ p: 4 }}>
              <Outlet />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}
