import React, { useEffect, useState } from "react"
import {
  Box,
  Stack,
  Typography,
  Link,
  TextField,
  Button,
  Divider,
  InputLabel,
  CircularProgress,
} from "@mui/material"
import googlePlayBadgePL from "../assets/images/pl/google-play-badge.png"
import googlePlayBadgeEN from "../assets/images/en/google-play-badge.png"
import googlePlayBadgePOB from "../assets/images/pob/google-play-badge.png"
import appStoreBadgePL from "../assets/images/pl/app-store-badge.svg"
import appStoreBadgeEN from "../assets/images/en/app-store-badge.svg"
import appStoreBadgePOB from "../assets/images/pob/app-store-badge.svg"
import { useTranslation } from "react-i18next"
import { SubmitHandler, useForm } from "react-hook-form"
import { useAuthContext } from "../contexts"
import { useNavigate } from "react-router-dom"
import facebookIcon from "../assets/images/facebook-icon.svg"
import { useSignInPatient } from "../hooks/useSignInPatient"
import { useSignInPatientWithFacebook } from "../hooks/useSignInPatientWithFacebook"

type FormInputs = {
  email: string
  password: string
}

const googlePlayBadgeMap = {
  en: googlePlayBadgeEN,
  pl: googlePlayBadgePL,
  pob: googlePlayBadgePOB,
}

const appStoreBadgeMap = {
  en: appStoreBadgeEN,
  pl: appStoreBadgePL,
  pob: appStoreBadgePOB,
}

export const LoginPatient: React.FC = () => {
  const { signedInEntity } = useAuthContext()
  const navigate = useNavigate()
  const [showForm, setShowForm] = useState(false)

  useEffect(() => {
    if (signedInEntity) {
      navigate("/results")
    }
  }, [signedInEntity])

  if (signedInEntity) {
    return null
  }

  return (
    <div>{showForm ? <LoginForm /> : <Information onButtonClick={() => setShowForm(true)} />}</div>
  )
}

type InformationProps = {
  onButtonClick: () => void
}

const Information = ({ onButtonClick }: InformationProps) => {
  const { t, i18n } = useTranslation()
  const googlePlayBadge = googlePlayBadgeMap[i18n.language as keyof typeof googlePlayBadgeMap]
  const appStoreBadge = appStoreBadgeMap[i18n.language as keyof typeof appStoreBadgeMap]

  return (
    <Box minHeight={278}>
      <Stack spacing={1}>
        <Typography align="center" variant="h2">
          {t("login.patient.header1")}
        </Typography>
        <Typography variant="body1">{t("login.patient.caption")}</Typography>
      </Stack>
      <Box mt={4}>
        <Button sx={{ borderRadius: "12px" }} fullWidth variant="contained" onClick={onButtonClick}>
          {t("login.patient.buttonSignIn")}
        </Button>
      </Box>
      <Box mt={4} mb={4}></Box>
      <Typography align="center" variant="h2">
        {t("login.patient.header2")}
      </Typography>
      <Box mt={1}>
        <Typography variant="body1" component="ol">
          <li>{t("login.patient.step1")}</li>
          <li>{t("login.patient.step2")}</li>
        </Typography>
      </Box>
      <Stack alignItems="center" justifyContent="space-between" direction="row" mt={4}>
        <Link
          href="https://play.google.com/store/apps/details?id=pl.teczkapacjenta"
          target="_blank"
        >
          <img src={googlePlayBadge} width={155} alt="Pobierz z Google Play" />
        </Link>
        <Link href="https://apps.apple.com/pl/app/teczka-pacjenta/id6464666410" target="_blank">
          <img src={appStoreBadge} width={155} alt="Pobierz z Google Play" />
        </Link>
      </Stack>
    </Box>
  )
}

const LoginForm = () => {
  const { t } = useTranslation()
  const { handleSubmit, register } = useForm<FormInputs>()
  const { signIn, isPending, isSuccess, error } = useSignInPatient()
  const { signIn: signInWithFacebook } = useSignInPatientWithFacebook()
  const { isUnauthorizedUserInDBError } = useAuthContext()
  const handleLoginSubmit: SubmitHandler<FormInputs> = data => {
    signIn({ email: data.email, password: data.password })
  }

  const handleSignInWithFacebookClick = () => {
    signInWithFacebook()
  }

  const inProgress = isPending || isSuccess
  return (
    <>
      <Stack spacing={2}>
        <Typography align="center" variant="h2">
          {t("login.patient.header1")}
        </Typography>
        <Button
          variant="outlined"
          fullWidth
          startIcon={<img src={facebookIcon} alt="Facebook Icon" />}
          onClick={handleSignInWithFacebookClick}
          color="primary"
          sx={{
            borderRadius: "12px",
            borderColor: "#D0D5DD",
            color: "#101828",
            fontWeight: "600",
          }}
        >
          {t("login.patient.buttonFacebook")}
        </Button>
        <Box mt={2} mb={2}>
          {isUnauthorizedUserInDBError && (
            <Typography color="error" align="center">
              {t("login.patient.error.auth/user-not-in-db")}
            </Typography>
          )}
        </Box>
      </Stack>
      <Box mt={2} mb={2}>
        <Divider sx={{ color: "#98A2B3" }}>{t("login.patient.divider")}</Divider>
      </Box>
      <form onSubmit={handleSubmit(handleLoginSubmit)}>
        <Stack direction="column" spacing={3}>
          <Stack spacing={1}>
            <InputLabel htmlFor="email">E-mail</InputLabel>
            <TextField
              placeholder={t("login.patient.emailPlaceholder")}
              id="email"
              {...register("email", { required: "Email is required" })}
            />
          </Stack>
          <Stack spacing={1}>
            <InputLabel htmlFor="password">{t("login.patient.password")}</InputLabel>
            <TextField
              type="password"
              id="password"
              placeholder="••••••••••"
              {...register("password", { required: "Password is required" })}
            />
          </Stack>
          {error && <Typography color="red">{t(`login.patient.error.${error.code}`)}</Typography>}
          <Button
            sx={{ borderRadius: "12px" }}
            variant="contained"
            fullWidth
            type="submit"
            disabled={inProgress}
            endIcon={inProgress ? <CircularProgress size={24} /> : null}
          >
            {t("login.patient.buttonSignIn")}
          </Button>
        </Stack>
      </form>
    </>
  )
}
