import React, { useCallback, useEffect, useState } from "react"
import { Box, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { PinCode } from "../components/PinCode"
import {
  CodeExpiredError,
  CodeNotFoundError,
  authorizeWithShareCode,
  getShareCodeStatus,
} from "../api/auth"
import { CountDown } from "../components/CountDown"
import { DisplayShareCode } from "../components/DisplayShareCode"
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { differenceInSeconds } from "date-fns"
import { useTranslation } from "react-i18next"
import { useAuthContext } from "../contexts"

enum LoginFormState {
  FirstAttempt = "FIRST_ATTEMPT",
  CodeExpired = "CODE_EXPIRED",
  WaitingVerification = "WAITING_VERIFICATION",
}

export const LoginDoctor = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [loginError, setLoginError] = useState<string | undefined>()
  const [code, setCode] = useState("")
  const [codeId, setCodeId] = useState("")
  const [loginFormState, setLoginFormState] = useState<LoginFormState>(LoginFormState.FirstAttempt)
  const [expirationTimeInSeconds, setExpirationTimeInSeconds] = useState(110)
  const { signInDoctor, signedInEntity } = useAuthContext()

  const errorMessages = {
    codeExpired: t("login.form.error.codeExpired"),
    codeNotFound: t("login.form.error.codeNotFound"),
  }

  const login = useCallback(
    async (code: string) => {
      setCode(code)
      authorizeWithShareCode(code)
        .then(response => {
          if (response.codeId) {
            setLoginFormState(LoginFormState.WaitingVerification)
            setCodeId(response.codeId)
            if (response.expiresAt) {
              setExpirationTimeInSeconds(
                differenceInSeconds(new Date(response.expiresAt), new Date()) || 110
              )
            }
            return
          }
          const { token } = response
          signInDoctor(token)
          navigate("/results")
        })
        .catch(error => {
          if (error instanceof CodeExpiredError) {
            setLoginError(errorMessages.codeExpired)
          }
          if (error instanceof CodeNotFoundError) {
            setLoginError(errorMessages.codeNotFound)
          }
        })
    },
    [navigate, errorMessages.codeExpired, errorMessages.codeNotFound]
  )

  useEffect(() => {
    const checkCodeStatus = () => {
      if (codeId) {
        setLoginFormState(LoginFormState.WaitingVerification)
        getShareCodeStatus(codeId)
          .then(response => response.json())
          .then(data => {
            const { status } = data

            if (status === "APPROVED") {
              clearInterval(checkingStatusInterval)
              login(code)
            }
          })
          .catch(error => {
            console.error("Error fetching data:", error)
          })
      }
    }

    const checkingStatusInterval = setInterval(checkCodeStatus, 1000)

    return () => {
      clearInterval(checkingStatusInterval)
    }
  }, [codeId, code, login])

  useEffect(() => {
    if (signedInEntity) {
      navigate("/results")
    }
  }, [signedInEntity])

  const onFinishTimer = () => {
    setLoginFormState(LoginFormState.CodeExpired)
    setCodeId("")
  }

  const renderLoginForm = () => {
    switch (loginFormState) {
      case LoginFormState.FirstAttempt:
        return (
          <>
            <Typography variant="body1" component="ol">
              <li>{t("login.form.step1")}</li>
              <li>{t("login.form.step2")}</li>
            </Typography>
            <PinCode onSubmit={login} errorMessage={loginError} />
          </>
        )
      case LoginFormState.WaitingVerification:
        return (
          <>
            <Typography variant="body1" component="ol">
              <li>{t("login.form.waitingVerification")}</li>
            </Typography>
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
              <DisplayShareCode code={code} />
              <CountDown duration={expirationTimeInSeconds} onFinish={onFinishTimer} />
            </Box>
          </>
        )
      case LoginFormState.CodeExpired:
        return (
          <>
            <Box my={2} display="flex">
              <Typography variant="body2" sx={{ mr: 1 }}>
                <FontAwesomeIcon icon={faCircleExclamation} color="#FF3A3A" />
              </Typography>
              <Typography variant="body2">
                {t("login.form.codeExpiredMessage", {
                  code,
                })}
              </Typography>
            </Box>
            <Typography variant="body1">{t("login.form.codeExpiredInfo")}</Typography>
            <PinCode onSubmit={login} errorMessage={loginError} />
          </>
        )
    }
  }

  if (signedInEntity) {
    return null
  }

  return (
    <>
      <Typography variant="h2">{t("login.form.header")}</Typography>
      <Box mt={1}>{renderLoginForm()}</Box>
    </>
  )
}
