import { useAuthContext } from "../contexts"
import { useMutation } from "@tanstack/react-query"
import { postCreateDocument } from "../api/document"
import { CreateDocumentInputs } from "../components/results/ResultsForm"

export const usePostCreateDocument = () => {
  const { signedInEntity } = useAuthContext()
  const { mutate, data, isError, isPending, error } = useMutation({
    mutationKey: ["postCreateDocument"],
    mutationFn: async (formData: CreateDocumentInputs) => {
      await postCreateDocument({ data: formData, token: signedInEntity?.token ?? "" })
    },
  })
  return { mutate, data, isPending, isError, error}
}
