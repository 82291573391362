import { faCircleInfo } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Box,
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { format } from "date-fns/fp"
import React from "react"
import { useNavigate } from "react-router"
import { StyledDocumentListItem } from "./StyledDocumentListItem"
import { DocumentCategory, DocumentStatus } from "../types/document"
import { MeasurementNorm } from "./MeasurementNorm"
import { DocumentPreviewButton } from "./DocumentPreviewButton"
import { ExaminationType } from "../utils"
import { Measurement } from "../types/measurement"
import { DocumentCategoryIcon } from "./DocumentCategoryIcon"
import { useTranslation } from "react-i18next"

export interface SearchDocumentListProps {
  documents: Array<{
    documentId: string
    samplingDate: string
    status: DocumentStatus
    category: DocumentCategory
    title: string
    examinations: Array<{
      type: ExaminationType
      measurements: Measurement[]
    }>
  }>
}

export const SearchDocumentList: React.FC<SearchDocumentListProps> = ({ documents }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const showDetails = (documentId: string) => {
    return navigate(`${documentId}`)
  }
  return (
    <>
      {documents.length === 0 ? (
        <Box>
          <Typography>
            <FontAwesomeIcon icon={faCircleInfo} color="#597FFF" />{" "}
            {t("documentList.noDocumentsFound")}
          </Typography>
        </Box>
      ) : (
        <TableContainer>
          <Table sx={{ borderSpacing: "0 16px", borderCollapse: "separate" }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Grid container columnSpacing={{ xs: 0, sm: 2, md: 2 }}>
                    <Grid item md={2.5} sm={3} xxs={12}>
                      <Box sx={{ paddingLeft: 6 }}>{t("documentList.date")}</Box>
                    </Grid>
                    <Grid item md={8} sm={9} xxs={12}>
                      <Grid container>
                        <Grid item xxs>
                          {t("documentList.parameters")}
                        </Grid>
                        <Grid item xxs>
                          {t("documentList.results")}
                        </Grid>
                        <Grid item xxs>
                          {t("documentList.referenceRange")}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documents.map(document => (
                <StyledDocumentListItem key={document.documentId}>
                  {document.category === DocumentCategory.Lab ? (
                    <TableCell>
                      <Grid container columnSpacing={{ md: 1, sm: 2 }}>
                        <Grid item xxs={12} xs={5} sm={3.5} md={2.5} display="flex">
                          <DocumentCategoryIcon
                            category={document.category}
                            sx={{ mr: 2, width: 28, height: 28 }}
                          />
                          <Typography>
                            {format("dd.MM.yyyy", new Date(document.samplingDate))}
                          </Typography>
                        </Grid>
                        <Grid item xxs={12} xs={7} sm={8.5} md={4.5}>
                          <Typography noWrap>
                            <Typography fontWeight={700} component="span">
                              {t(`documentCategory.${document.category.toLowerCase()}`)}
                            </Typography>
                            <Typography
                              component="span"
                              fontStyle={document.title.length > 0 ? "normal" : "italic"}
                            >
                              : {document.title || t(`documentList.noTitle`)}
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item md={5} xxs={12}>
                          <Grid
                            container
                            justifyContent="flex-end"
                            columnSpacing={1}
                            rowSpacing={0.5}
                          >
                            <Grid item>
                              <Button
                                onClick={() => showDetails(document.documentId)}
                                color="primary"
                                variant="outlined"
                                size="small"
                              >
                                {t("documentList.buttonText")}
                              </Button>
                            </Grid>
                            <Grid item>
                              <DocumentPreviewButton
                                documentId={document.documentId}
                                size="small"
                                buttonText={t("documentList.previewButtonText")}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xxs={12} sx={{ mt: 1 }}>
                          {document.examinations.map((examination, index) => (
                            <Grid
                              key={`${examination}-${index}`}
                              container
                              columnSpacing={1}
                              alignItems="flex-start"
                              sx={{
                                borderTop: "1px solid #D0D4D7",
                                paddingTop: 1,
                                paddingBottom: { md: 2, sm: 1 },
                              }}
                            >
                              <Grid item md={3} sm={3} xxs={12}>
                                <Typography>{t("examinationType." + examination.type)}</Typography>
                              </Grid>
                              <Grid item md={7} sm={9} xxs={12}>
                                <Box component="ul" width="100%">
                                  {examination.measurements.map((measurement, index) => (
                                    <Box component="li" mr={3} key={`${measurement}-${index}`}>
                                      <Grid container rowGap={2}>
                                        <Grid item xxs>
                                          <Typography variant="body2">
                                            {measurement.name}
                                          </Typography>
                                        </Grid>
                                        <Grid item xxs alignItems="center">
                                          <Typography variant="body2">
                                            {measurement.value} {measurement.unit}
                                          </Typography>
                                        </Grid>
                                        <Grid item xxs>
                                          <Stack direction="row" columnGap={1}>
                                            <MeasurementNorm norm={measurement.normReference} />
                                            {measurement.normReference === "UNKNOWN" ? (
                                              <Typography variant="body2">
                                                {t("documentReadingError.referenceRangeFailed")}
                                              </Typography>
                                            ) : (
                                              <Typography variant="body2">{`${measurement.min} - ${measurement.max}`}</Typography>
                                            )}
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  ))}
                                </Box>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </TableCell>
                  ) : (
                    <TableCell>
                      <Grid container columnSpacing={{ md: 1 }}>
                        <Grid item xxs={12} xs={5} sm={3.5} md={2.5} display="flex">
                          <DocumentCategoryIcon
                            category={document.category}
                            sx={{ mr: 2, width: 28, height: 28 }}
                          />
                          <Typography>
                            {format("dd.MM.yyyy", new Date(document.samplingDate))}
                          </Typography>
                        </Grid>
                        <Grid item xxs={12} xs={7} sm={5.5} md={7} display="flex">
                          <Typography noWrap>
                            <Typography fontWeight={700} component="span">
                              {t(`documentCategory.${document.category.toLowerCase()}`)}
                            </Typography>
                            <Typography
                              component="span"
                              fontStyle={document.title.length > 0 ? "normal" : "italic"}
                            >
                              : {document.title || t(`documentList.noTitle`)}
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item md sm xs={12} xxs={12} display="flex" justifyContent="flex-end">
                          <DocumentPreviewButton
                            documentId={document.documentId}
                            size="small"
                            buttonText={t("documentList.previewButtonText")}
                          />
                        </Grid>
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          xxs={12}
                          sx={{
                            mt: 1,
                            borderTop: "1px solid #D0D4D7",
                            paddingTop: 1,
                            paddingBottom: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: 14,
                              color: "#526D75",
                            }}
                          >
                            {t(`documentList.searchedPhraseFound`)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  )}
                </StyledDocumentListItem>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}
