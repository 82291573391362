import { FileWithPath, useDropzone } from "react-dropzone"
import { useEffect, useMemo, useCallback } from "react"
import { Stack, Typography } from "@mui/material"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { useTranslation } from "react-i18next"

const MAX_SIZE_IN_BYTES = 1048576

const baseStyle = {
  flex: 1,
  display: "flex",
  alignItems: "center",
  padding: "70px",
  borderWidth: "2px",
  borderRadius: "20px",
  borderColor: "grey",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "black",
  transition: "border .24s ease-in-out",
}

interface DocumentDropZoneProps {
  onFilesAccepted: (files: FileWithPath[]) => void
}

export const DocumentDropZone = ({ onFilesAccepted }: DocumentDropZoneProps) => {
  const { t } = useTranslation()
  const memoizedOnFilesAccepted = useCallback((files: FileWithPath[]) => {
    onFilesAccepted(files)
  }, [onFilesAccepted])

  const {
    fileRejections,
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    // accept: { "image/*": [".jpeg", ".png"], "application/pdf/*": [".pdf"] },
    accept: { "image/jpeg": [], "image/png": [], "application/pdf": [] },
    multiple: false,
    maxSize: MAX_SIZE_IN_BYTES,
  })

  const style = useMemo(() => ({
    ...baseStyle,
  }), [isFocused, isDragAccept, isDragReject])

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      memoizedOnFilesAccepted([...acceptedFiles])
    }
  }, [acceptedFiles, memoizedOnFilesAccepted])

  const errorCode = fileRejections.length > 0 && fileRejections[0]?.errors[0]?.code;
  return (
    <>
      <Stack {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <CloudUploadIcon sx={{ fontSize: 56 }} />
        <Typography variant="h5" align="center" fontWeight="bold" sx={{ marginTop: "10px" }}>
          {t("results.upload.drag")}
          <span
            style={{
              color: "#1976d2",
              cursor: "pointer",
              marginLeft: "4px",
              marginRight: "4px",
            }}
          >
            {t("results.upload.choose")}
          </span>
          {t("results.upload.send")}
        </Typography>
        <Typography variant="h6" align="center" sx={{ marginTop: "10px" }}>
          {t("results.upload.info")}
        </Typography>
        {errorCode && (
          <Typography color="error" align="center">
            {t(`dropZone.${errorCode}`)}
          </Typography>
        )}
      </Stack>
    </>
  )
}
