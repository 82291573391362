import { useMutation, useQueryClient } from "@tanstack/react-query"
import { signInPatient } from "../api/auth"
import { FirebaseError } from "firebase/app"

export const useSignInPatient = () => {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, isError, isSuccess, error } = useMutation<
    void,
    FirebaseError,
    { email: string; password: string }
  >({
    mutationKey: [signInPatient.name],
    mutationFn: async ({ email, password }) => {
      await signInPatient(email, password)
      queryClient.removeQueries()
    },
  })

  return {
    signIn: mutateAsync,
    isPending,
    isError,
    isSuccess,
    error: error,
  }
}
