import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Stack, Typography } from "@mui/material"
import React from "react"

export interface LoaderProps {
  message?: string
}

export const Loader: React.FC<LoaderProps> = ({ message }) => {
  return (
    <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" mt={5}>
      <FontAwesomeIcon icon={faSpinner} spinPulse />
      <Typography>{message || "Wczytywanie..."}</Typography>
    </Stack>
  )
}
