import { TableCell, Grid, Typography, Button } from "@mui/material"
import { format } from "date-fns/fp"
import React from "react"
import { ExaminationType } from "../utils"
import { DocumentCategoryIcon } from "./DocumentCategoryIcon"
import { DocumentPreviewButton } from "./DocumentPreviewButton"
import { MeasurementList } from "./MeasurementList"
import { DocumentCategory, DocumentStatus } from "../types/document"
import { useNavigate } from "react-router-dom"
import { Measurement } from "../types/measurement"
import { useTranslation } from "react-i18next"

export interface LabDocumentItemProps {
  documentId: string
  samplingDate: string
  status: DocumentStatus
  category: DocumentCategory
  title: string
  examinations: Array<{
    type: ExaminationType
    measurements: Measurement[]
  }>
}

export const LabDocumentItem: React.FC<LabDocumentItemProps> = ({
  documentId,
  category,
  title,
  samplingDate,
  status,
  examinations,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const showDetails = (documentId: string) => {
    return navigate(`${documentId}`)
  }
  const isProcessed = [DocumentStatus.Verified, DocumentStatus.Skipped].includes(status)
  const isProcessing = status === DocumentStatus.InProgress
  const isError = status === DocumentStatus.ProcessFail
  return (
    <TableCell>
      <Grid container columnSpacing={{ md: 1, sm: 2 }}>
        <Grid item xxs={12} xs={5} sm={3.5} md={2.5} display="flex">
          <DocumentCategoryIcon category={category} sx={{ mr: 2, width: 28, height: 28 }} />
          <Typography>{format("dd.MM.yyyy", new Date(samplingDate))}</Typography>
        </Grid>
        <Grid item xxs={12} xs={7} sm={8.5} md={4.5}>
          <Typography noWrap>
            <Typography fontWeight={700} component="span">
              {t(`documentCategory.${category.toLowerCase()}`)}
            </Typography>
            <Typography component="span" fontStyle={title.length > 0 ? "normal" : "italic"}>
              : {title || t("documentList.noTitle")}
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={5} sm={12}>
          <Grid container justifyContent="flex-end" columnSpacing={1} rowSpacing={0.5}>
            {isProcessed ? (
              <Grid item>
                <Button
                  onClick={() => showDetails(documentId)}
                  color="primary"
                  variant="outlined"
                  size="small"
                >
                  {t("documentList.buttonText")}
                </Button>
              </Grid>
            ) : null}
            <Grid item>
              {isProcessing ? (
                <Button
                  color="warning"
                  variant="contained"
                  size="small"
                  sx={{
                    cursor: "default",
                    borderRadius: 25,
                    paddingX: 1.5,
                  }}
                >
                  {t("documentList.processingButtonText")}
                </Button>
              ) : null}
              {isError ? (
                <Button
                  color="error"
                  variant="contained"
                  size="small"
                  sx={{
                    cursor: "default",
                    borderRadius: 25,
                    paddingX: 1.5,
                  }}
                >
                  {t("documentList.errorButtonText")}
                </Button>
              ) : null}
            </Grid>
            <Grid item>
              <DocumentPreviewButton
                documentId={documentId}
                size="small"
                buttonText={t("documentList.previewButtonText")}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} sx={{ mt: 1 }}>
          {examinations.map((examination, index) => (
            <Grid
              key={`${examination}-${index}`}
              container
              sx={{
                borderTop: "1px solid #D0D4D7",
                paddingTop: 1,
                paddingBottom: { md: 2, sm: 1 },
              }}
              columnSpacing={{ sm: 1, md: 1 }}
              alignItems="flex-start"
            >
              <Grid item xs={12} sm={12} md={3}>
                <Typography>{t("examinationType." + examination.type)}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={9} mb={2}>
                <MeasurementList measurements={examination.measurements.map(m => m.name)} />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </TableCell>
  )
}
