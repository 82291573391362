import React, { useContext } from "react"
import { Link, useSearchParams } from "react-router-dom"
import { DocumentList } from "./DocumentList"
import { SearchDocumentList } from "../SearchDocumentList"
import { Box, Button, Typography } from "@mui/material"
import { SearchTerms } from "../SearchTerms"
import { DocumentsContext } from "../../context"
import AddIcon from "@mui/icons-material/Add"
import { useTranslation } from "react-i18next"
import { DocumentsEmptyList } from "./DocumentsEmptyList"
enum ViewType {
  search = "SEARCH",
  list = "LIST",
}

export const DocumentListWrapper: React.FC = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const terms = searchParams.getAll("terms")
  const view: ViewType = terms.length > 0 ? ViewType.search : ViewType.list

  const documents = useContext(DocumentsContext)
  return documents.length ? (
    <DocumentsContext.Provider value={documents}>
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="stretch">
          <Typography variant="h5" fontWeight="bold">
            {t("results.header")}
          </Typography>
          <Button
            sx={{ borderRadius: "20px" }}
            component={Link}
            to="/results/add"
            size="medium"
            startIcon={<AddIcon />}
            variant="contained"
          >
            {t("results.addButton")}
          </Button>
        </Box>
        <SearchTerms />
        {view === "SEARCH" ? (
          <SearchDocumentList documents={documents as any} />
        ) : (
          <DocumentList documents={documents as any} />
        )}
      </Box>
    </DocumentsContext.Provider>
  ) : (
    <DocumentsEmptyList />
  )
}
