import { useSearchParams } from "react-router-dom"
import { useAuthContext } from "../contexts"
import { useQuery } from "@tanstack/react-query"
import { getDoctorDocumentList, getPatientDocumentList } from "../api/document"

export const useGetDocuments = () => {
  const [searchParams] = useSearchParams()
  const searchTerms = searchParams.getAll("terms")
  const cat = searchParams.getAll("cat")

  const { signedInEntity } = useAuthContext()
  const { data, isLoading, isError } = useQuery({
    queryKey: [getPatientDocumentList.name, searchTerms.join(), cat.join()],
    queryFn: async () => {
      const fn = signedInEntity?.type === "doctor" ? getDoctorDocumentList : getPatientDocumentList
      return await fn({
        token: signedInEntity?.token ?? "",
        query: searchTerms,
        cat,
      })
    },
    enabled: !!signedInEntity,
  })

  return {
    documents: data?.documents,
    isLoading,
    isError,
  }
}
