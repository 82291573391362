import { useAuthContext } from "../contexts"
import { useQuery } from "@tanstack/react-query"
import { getDoctorDocumentDetails, getPatientDocumentDetails } from "../api/document"

export const useGetDocumentDetails = (documentId: string) => {
  const { signedInEntity } = useAuthContext()
  const { data, isLoading, isError } = useQuery({
    queryKey: [getDoctorDocumentDetails.name],
    queryFn: async () => {
      const handler =
        signedInEntity?.type === "doctor" ? getDoctorDocumentDetails : getPatientDocumentDetails
      return await handler({ documentId, token: signedInEntity?.token ?? "" })
    },
  })

  return { data, isLoading, isError }
}
