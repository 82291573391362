import React from "react"
import { Table, TableHead, TableRow, TableCell, TableBody, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

export interface ExaminationTypeHeaderProps {
  type: string
}

export const ExaminationTypeHeader: React.FC<ExaminationTypeHeaderProps> = ({ type }) => {
  const { t } = useTranslation()
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ padding: 0 }}>{t("examinationTypeHeader.testType")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell sx={{ paddingX: 0, paddingY: "9px" }}>
            <Typography variant="h2" sx={{ margin: 0, padding: 0 }}>
              {type}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
