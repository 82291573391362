import { TableCell, Grid, Typography } from "@mui/material"
import { format } from "date-fns/fp"
import React from "react"
import { DocumentCategoryIcon } from "./DocumentCategoryIcon"
import { DocumentPreviewButton } from "./DocumentPreviewButton"
import { DocumentCategory } from "../types/document"
import { useTranslation } from "react-i18next"

export interface OtherDocumentItemProps {
  documentId: string
  samplingDate: string
  category: DocumentCategory
  title: string
}

export const OtherDocumentItem: React.FC<OtherDocumentItemProps> = ({
  documentId,
  category,
  samplingDate,
  title,
}) => {
  const { t } = useTranslation()
  return (
    <TableCell>
      <Grid container columnSpacing={{ md: 1 }}>
        <Grid item xxs={12} xs={5} sm={3.5} md={2.5} display="flex">
          <DocumentCategoryIcon category={category} sx={{ mr: 2, width: 28, height: 28 }} />
          <Typography>{format("dd.MM.yyyy", new Date(samplingDate))}</Typography>
        </Grid>
        <Grid item xxs={12} xs={7} sm={5.5} md={7} display="flex">
          <Typography noWrap>
            <Typography fontWeight={700} component="span">
              {t(`documentCategory.${category.toLowerCase()}`)}
            </Typography>
            <Typography component="span" fontStyle={title.length > 0 ? "normal" : "italic"}>
              : {title || t(`documentList.noTitle`)}
            </Typography>
          </Typography>
        </Grid>
        <Grid item md sm display="flex" justifyContent="flex-end">
          <DocumentPreviewButton
            documentId={documentId}
            size="small"
            buttonText={t("documentList.previewButtonText")}
          />
        </Grid>
      </Grid>
    </TableCell>
  )
}
