import { apiURL, createDefaultHeaders } from "."

type UserDto = {
  firstName: string
  lastName: string
}

export class Unauthorized extends Error {
  // eslint-disable-next-line
  constructor(message: string) {
    super(message)
  }
}

export const getMe = async ({ token }: { token: string }): Promise<UserDto> => {
  const url = `${apiURL}/users/me`

  const response = await fetch(url, { headers: createDefaultHeaders(token) })
  if (response.status === 401) {
    throw new Unauthorized("User not found")
  }
  if (!response.ok) {
    throw new Error("Request failed")
  }
  return await response.json()
}
