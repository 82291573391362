import React, { useEffect } from "react"
import * as z from "zod"
import { useForm, Controller } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { FormControl, TextField, Button, Stack } from "@mui/material"
import { useTranslation } from "react-i18next"

const schema = z.object({
  code: z
    .string()
    .min(1, "codeNotEntered")
    .regex(/^\d{6}$/, "invalidCodeFormat"),
})

type FormInput = {
  code: string
}

export interface PinCodeProps {
  onSubmit: (code: string) => Promise<void>
  errorMessage?: string
}

export const PinCode: React.FC<PinCodeProps> = ({ onSubmit, errorMessage }) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    formState: { errors },
    control,
    setError,
  } = useForm<FormInput>({
    resolver: zodResolver(schema),
  })

  useEffect(() => {
    if (errorMessage !== undefined) {
      setError("code", { type: "auth", message: errorMessage })
    }
  }, [errorMessage, setError])

  const submitHandler = handleSubmit(({ code }: FormInput) => {
    onSubmit(code.trim())
  })

  return (
    <Stack justifyContent="center" mt={4}>
      <form onSubmit={submitHandler} autoComplete="false" noValidate>
        <FormControl fullWidth>
          <Controller
            name="code"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <TextField
                label={t("login.form.placeholder")}
                id="code"
                placeholder="000000"
                helperText={t(errors.code?.message ?? " ")}
                error={Boolean(errors.code)}
                required
                {...field}
              />
            )}
          />
          <Button variant="contained" size="large" sx={{ marginTop: 2 }} type="submit">
            {t("login.form.buttonText")}
          </Button>
        </FormControl>
      </form>
    </Stack>
  )
}
