import React from "react"
import { Box } from "@mui/material"
import { MainBar } from "../components"
import { Outlet, useLocation } from "react-router-dom"
import ScrollToTop from "../components/ScrollToTop"
import { Footer } from "./Footer"

export const Layout: React.FC = () => {
  const location = useLocation()
  const excludePaths = ["/terms", "/privacy-policy", "/register-confirm", "/support"]
  const hideNav = excludePaths.includes(location.pathname)

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <ScrollToTop />
      {!hideNav && <MainBar />}
      {hideNav ? <Outlet /> : <Outlet />}
      {!hideNav && <Footer />}
    </Box>
  )
}
