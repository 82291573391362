import { useEffect, useState } from "react"
import { onAuthStateChanged, signOut, User } from "firebase/auth"
import { firebaseAuth } from "../firebaseAuth"

export const useFirebaseAuth = () => {
  const [user, setUser] = useState<User | null>(null)
  const [isUserLoading, setIsUserLoading] = useState(true)

  const signOutUser = async () => {
    await signOut(firebaseAuth)
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, user => {
      if (user) {
        setUser(user)
      } else {
        setUser(null)
      }
      setIsUserLoading(false)
    })

    return () => unsubscribe()
  }, [])

  return { user, isUserLoading, isAuthenticated: !!user, signOutUser }
}
