import * as React from "react"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Stack from "@mui/material/Stack"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { NavLink, useLocation } from "react-router-dom"
import useBreadcrumbs from "use-react-router-breadcrumbs"
import { routes } from "../App"
import { Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

export const ShareResultsNav: React.FC = () => {
  const { i18n } = useTranslation()
  const breadcrumbs = useBreadcrumbs(routes(i18n.language), {
    excludePaths: ["/"],
  })
  const location = useLocation()

  return (
    <Stack spacing={2}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {breadcrumbs.map(({ match, breadcrumb }) =>
          location.pathname === match.pathname ? (
            <Typography
              key={match.pathname}
              color="secondary"
              sx={{ borderBottom: "4px solid #597FFF", paddingBottom: "1px" }}
            >
              {breadcrumb}
            </Typography>
          ) : (
            <NavLink key={match.pathname} to={match.pathname}>
              {breadcrumb}
            </NavLink>
          )
        )}
      </Breadcrumbs>
    </Stack>
  )
}
